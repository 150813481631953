<!-- 结算单创建 -->
<template>
  <Modal v-model="showFeeitemsModal" width="1000" :closable="false">
    <Row class="p-t-10">
      <i-col span="12" v-if="query.feeitemType === 2">
        <Row :gutter="8">
          <i-col span="12" class="p-b-5">
            <DatePicker
              placeholder="质检起始日期"
              type="date"
              v-model="qualityCheckStartDate"
              size="small"
              style="width: 100%"
              @on-change="handDateChange"
              format="yyyy-MM-dd"
            ></DatePicker>
          </i-col>
          <i-col span="12" class="p-b-5">
            <DatePicker
              placeholder="质检结束日期"
              type="date"
              size="small"
              v-model="qualityCheckEndDate"
              placement="bottom-end"
              style="width: 100%"
              @on-change="handDateChange"
              format="yyyy-MM-dd"
            ></DatePicker>
          </i-col>
        </Row>
      </i-col>

    </Row>

    <Table
       stripe border
      :data="list"
      :height="500"
      :columns="tableColumns"
      @on-selection-change="selectionChange"
    ></Table>

    <Row>
      <i-col span='12'>
        <span style="height: 28px;line-height: 28px;">已选中{{chkfeeitemIds.length}}条</span>
        </i-col>
        <i-col span="12">
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            :current="query.pageNumber"
            @on-change="handlePageChanged"
            show-sizer
            :page-size-opts="[15, 50, 100, 200, 500]"
            @on-page-size-change="handlePageSizeChanged"
          ></Page>
        </div>
      </i-col>
    </Row>

    <div slot="footer">
        <Button type="text"  @click="showFeeitemsModal=false">取消</Button>
        <Button type="primary"  @click="confirm">确认</Button>
      </div>

  </Modal>
</template>
<script>
import { toMoney } from '@/utils/wnumb_own'
import {
  getfeeitempage,
  batchaddorremovefeeitemlist,
  getmspfeeitempage
} from '@/api/statement/bill'
import { ParseDate, GetCurrentSchedule } from '@/utils/dateFormat'

export default {
  data () {
    return {
      showFeeitemsModal: false,
      companyArray: [],
      total: 0,
      lastPage: true,
      billId: 0,
      query: {
        feeitemType: 1,
        agentCompanyId: 0,
        billStatus: -1,
        pageSize: 15,
        pageNumber: 1
      },
      qualityCheckStartDate: '',
      qualityCheckEndDate: '',
      list: [],

      tableColumns: [],
      feeitemColumn: [
        {
          type: 'selection',
          key: 'feeitemId',
          width: 60,
          align: 'center'
        },
        {
          title: '合同编号',
          key: 'contractCode'
        },
        {
          title: '客户',
          key: 'advertiserName'
        },
        {
          title: '品牌',
          key: 'brandName',
          render: (h, params) => {
            return h('div', [h('p', params.row.brandName || '-')])
          }
        },
        {
          title: '合同总额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.contractAmount)
              )
            ])
          }
        },

        {
          title: '执行周期',
          key: 'startDate',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                GetCurrentSchedule(params.row.startDate, params.row.endDate)
              )
            ])
          }
        },
        {
          title: '执行金额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.periodExecutedAmount)
              )
            ])
          }
        }
      ],
      mspfeeitemColumn: [
        {
          type: 'selection',
          key: 'feeitemId',
          width: 60,
          align: 'center'
        },
        {
          title: '供应商',
          key: 'companyName',
          render: (h, params) => {
            return h('div', [h('p', params.row.supplierCompanyName)])
          }
        },

        {
          title: '代理商',
          key: 'createCompanyName',
          render: (h, params) => {
            return h('div', [h('p', params.row.createCompanyName)])
          }
        },
        {
          title: '资源编号',
          key: 'resourceCode',
          render: (h, params) => {
            return h('div', [h('p', params.row.resourceCode)])
          }
        },
        {
          title: '资产',
          key: 'code',
          render: (h, params) => {
            return h('div', [h('p', params.row.assetName)])
          }
        },
        {
          title: '位置',
          key: 'position',
          render: (h, params) => {
            return h('div', [h('p', params.row.position)])
          }
        },
        {
          title: '资源类型',
          key: 'startDate',
          render: (h, params) => {
            return h('div', [h('p', params.row.resourceTypeName)])
          }
        },

        {
          title: '作业类型',
          key: 'taskTypeName',
          render: (h, params) => {
            return h('div', [h('p', params.row.taskTypeName)])
          }
        },
        {
          title: '记账金额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        {
          title: '作业完成日期',
          key: 'taskRequireFinishTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.taskFinishTime)])
          }
        },
        {
          title: '质检时间',
          key: 'qualityCheckTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.qualityCheckTime)])
          }
        },
        {
          title: '记账时间',
          key: 'confirmTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.confirmTime)])
          }
        }
      ],

      currentPageAllItemIds: [],
      chkfeeitemIds: [],
      mark: ''
    }
  },
  methods: {
    initfeeitemColumn () {
      const that = this
      if (that.query.feeitemType === 1) {
        that.tableColumns = that.feeitemColumn
      } else {
        that.tableColumns = that.mspfeeitemColumn
      }
    },
    showModal (feeitemType, billDetail, billId) {
      this.list = []
      this.chkfeeitemIds = []
      this.billId = billId
      this.query.pageNumber = 1
      this.query.feeitemType = feeitemType
      if (feeitemType === 1) {
        this.query.agentCompanyId = billDetail.agentCompanyId
      } else if (feeitemType === 2) {
        this.query.purchaserCompanyId = billDetail.purchaserCompanyId
        this.query.supplierCompanyId = billDetail.supplierCompanyId
      }
      this.query.qualityCheckStartDate = null
      this.query.qualityCheckEndDate = null
      this.qualityCheckStartDate = null
      this.qualityCheckEndDate = null
      this.initfeeitemColumn()
      this.showFeeitemsModal = true
      if (billDetail) {
        this.initItemArray()
      }
    },

    // 加载所有可结算的交易项
    initItemArray () {
      const that = this
      if (that.query.feeitemType === 1) {
        getfeeitempage(this.query).then((res) => {
          res.list.map((item) => {
            item._checked = that.chkfeeitemIds.includes(item.feeitemId)
            return item
          })
          that.currentPageAllItemIds = res.list.map((item) => {
            return item.feeitemId
          })
          that.list = res.list
          that.total = res.totalRow
          that.lastPage = res.lastPage
        })
      } else if (that.query.feeitemType === 2) {
        if (that.qualityCheckStartDate) {
          that.query.qualityCheckStartDate = ParseDate(this.qualityCheckStartDate)
        } else {
          that.query.qualityCheckStartDate = null
        }
        if (that.qualityCheckEndDate) {
          that.query.qualityCheckEndDate = ParseDate(this.qualityCheckEndDate)
        } else {
          that.query.qualityCheckEndDate = null
        }

        getmspfeeitempage(this.query).then((res) => {
          res.list.map((item) => {
            item._checked = that.chkfeeitemIds.includes(item.feeitemId)
            return item
          })
          that.currentPageAllItemIds = res.list.map((item) => {
            return item.feeitemId
          })
          that.list = res.list
          that.total = res.totalRow
          that.lastPage = res.lastPage
        })
      }
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.initItemArray()
    },

    handlePageSizeChanged (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.initItemArray()
    },
    handDateChange () {
      this.query.pageNumber = 1
      this.initItemArray()
    },
    // 选中的结算项变更
    selectionChange (selection) {
      const that = this
      const selectionIds = selection.map((item) => {
        return item.feeitemId
      })
      // 循环当前页面所有列
      that.currentPageAllItemIds.forEach((item) => {
        // 列是否是选中状态
        if (selectionIds.includes(item)) {
          // 判断是否已在列表，不在则加入
          if (!that.chkfeeitemIds.includes(item)) {
            that.chkfeeitemIds.push(item)
          }
        } else {
          // 列未被选中
          // 判断是否已在列表，在则移除
          if (that.chkfeeitemIds.includes(item)) {
            that.chkfeeitemIds.splice(that.chkfeeitemIds.indexOf(item), 1)
          }
        }
      })
    },
    // 确定添加结算项
    confirm () {
      const that = this
      if (that.chkfeeitemIds.length) {
        const data = {
          billId: that.billId,
          feeitemType: that.query.feeitemType,
          feeitemListJsonStr: JSON.stringify(that.chkfeeitemIds),
          action: 1
        }
        batchaddorremovefeeitemlist(data).then((res) => {
          if (res && !res.errcode) {
            that.showFeeitemsModal = false
            that.$store.commit('set_reconciliationNew_update', new Date())
          }
        })
      } else {
        that.$Notice.info({ desc: '请至少选择一个结算项！' })
      }
    }
  }
}
</script>
