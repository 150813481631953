<template>
  <div>
    <div class="p-b-10">
      <Row>
        <i-col span="24" class="text-right" v-if="allowConfirm">
          <Button type="success" size="small" @click="confirmSettlement"
            >确认执行额</Button
          ></i-col
        >
      </Row>
      <div class="workplatform-title">{{  billDetail.name||' - ' }}({{billDetail.code}})<a style="font-size:12px;" v-if="allowEdit" @click="handleChangeName">修改名称</a></div>

      <Row v-if="feeitemType === 2">
          <i-col span="8">
            <span class="title">公司 </span>{{ billDetail.purchaserCompanyName }}
          </i-col>
          <i-col span="8">
            <span class="title">供应商 </span>{{ billDetail.supplierCompanyName }}
          </i-col>
          <i-col span="8">
            <span class="title">创建时间 </span>{{ billDetail.createTime }}
          </i-col>
        </Row>
        <Row  v-else>
          <i-col span="8">
            <span class="title">结算公司 </span>{{ billDetail.agentCompanyName }}
          </i-col>
          <i-col span="8">
            <span class="title">创建时间 </span>{{ billDetail.createTime }}
          </i-col>
        </Row>
        <Row >
          <i-col span="24">
            <span  class="title">最终结算金额 </span
            ><span class="money">{{
              formatMoney(billDetail.billAmount)
            }}</span>
          </i-col>
        </Row>
        <Row >
          <i-col span="8">
            <span class="title"> 计费项金额  </span>
            <span class="money">{{ formatMoney(billDetail.normalAmount) }}</span>
          </i-col>
          <i-col span="8">
            <span class="title"> 调整项⾦额  </span>
             <span class="money">{{ formatMoney(billDetail.adjustAmount) }}</span>
          </i-col>
          <i-col span="8">
            <span class="title"> 罚款项金额 </span>
             <span class="money">{{ formatMoney(billDetail.penaltyAmount) }}</span>
          </i-col>
        </Row>
        <Row v-if="feeitemType===2">
          <i-col span="24">
            <span class="title">资产 </span>
            {{ billDetail.zones || "-" }}
            <a v-if="allowEdit" @click="handleChangeAssets">修改资产</a>
          </i-col>
        </Row>
        <Row>
          <i-col span="24">
            <span class="title"> 备注: </span>
            {{ billDetail.mark || "-" }}
            <a v-if="allowEdit" @click="handleChangeCode">修改备注</a>
          </i-col>
        </Row>
    </div>
    <div class="p-t-10">
      <div class="workplatform-title">费用清单列表</div>
      <Tabs>
        <TabPane label="计费项">
          <template>
            <Row class="m-b-5">
              <i-col span="16">
                <Row :gutter="8">
                  <i-col span="18">
                    <i-input
                      type="text"
                      size="small"
                      v-model="feeitemQuery.keyword"
                      clearable
                      placeholder="关键字"
                    ></i-input>
                  </i-col>
                  <i-col span="6">
                    <Button
                      icon="ios-search"
                      type="primary"
                      size="small"
                      @click="searchfeeitems"
                    >
                      搜索
                    </Button>
                    <Button  v-if="allowEdit" type="primary" size="small" class="m-l-5" @click="addFeeitems"
                  >添加明细</Button
                >
                  </i-col>
                </Row>
              </i-col>
            </Row>
            <Table
              stripe
              class="m-t-5"

              :max-height="500"
              :data="feeitempage"
              :columns="column"
              :summary-method="handleSummary"
              show-summary
            ></Table>
            <div class="paging_style">
          <Page size="small"
            :total="feeitemTotal"
            :page-size="feeitemQuery.pageSize"
            show-total
            show-elevator
            show-sizer
            :page-size-opts="[15, 50, 100, 200, 500]"
            @on-page-size-change="handlePageSizeChanged"
            :current="feeitemQuery.pageNumber"
            @on-change="feeitemChangePage"
          ></Page>
        </div>
          </template>
        </TabPane>
        <TabPane label="调整项">
          <template>
            <Row class="m-b-5" v-if="allowEdit">
              <i-col span="24" class="text-right">
                <Button type="info" size="small" class="m-r-5" @click="modaluploadFile=true"
                  >附件管理</Button
                >
                <Button type="primary" size="small" @click="handleAdd"
                  >添加调整项</Button
                >
              </i-col>
            </Row>
            <Table
              stripe
              :data="adjustfeeitems"
              :max-height="500"
              :columns="adjustfeeitemColumn"
              :summary-method="handleSummary"
              show-summary
            ></Table>
             <div class="paging_style">
          <Page size="small"
            :total="adjustfeeitemTotal"
            :page-size="adjustfeeitemQuery.pageSize"
            show-total
            show-elevator
            show-sizer
            :page-size-opts="[15, 50, 100, 200, 500]"
            @on-page-size-change="handleadjustfeeitemPageSizeChanged"
            :current="adjustfeeitemQuery.pageNumber"
            @on-change="adjustfeeitemChangePage"
          ></Page>
        </div>
          </template>
        </TabPane>
        <TabPane label="罚款项">
          <template>
            <Row class="m-b-5" v-if="allowEdit">
              <i-col span="24" class="text-right">
                <Button type="primary" size="small" @click="addFineItems"
                  >添加罚款项</Button
                ></i-col
              >
            </Row>
            <Table
              stripe
              :data="fines"
              :max-height="500"
              :columns="fineColumns"
              :summary-method="handleSummary"
              show-summary
            ></Table>
             <div class="paging_style">
          <Page size="small"
            :total="fineTotal"
            :page-size="fineQuery.pageSize"
            show-total
            show-elevator
            show-sizer
            :page-size-opts="[15, 50, 100, 200, 500]"
            @on-page-size-change="handlefinePageSizeChanged"
            :current="fineQuery.pageNumber"
            @on-change="fineChangePage"
          ></Page>
        </div>
          </template>
        </TabPane>
        <TabPane label="含税汇总" v-if="feeitemType === 2">
          <template>
            <Table
              stripe
              :data="summary"
              :max-height="500"
              :columns="summaryColumns"
              :summary-method="handleSummary"
              show-summary
            ></Table>
          </template>
        </TabPane>
      </Tabs>
    </div>
    <AddFeeitem ref="addFeeitemModal"></AddFeeitem>
    <AddFineItem ref="addFineItemModal"></AddFineItem>
    <Modal v-model="modaluploadFile" width="800" :footer-hide="true" title="调整项附件">
      <div>
         <fileUpload type="STATEMENT_BILL_ADJUST" :relateId="billId" :format="fileTypes" ></fileUpload>
      </div>
    </Modal>
    <Modal v-model="modalAdd" width="800">
      <Form
        ref="adjustment"
        :model="formData"
        :rules="formDataValidate"
        label-position="top"
      >
        <FormItem label="费用类型" prop="feeType">
          <Select v-model="formData.feeType" placeholder="请选择费用类型">
            <Option
              v-for="item in adjustablefeetypeArray"
              :key="'ft_' + item.feeType"
              :value="item.feeType"
              >{{ item.feeTypeName }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="调整项金额" prop="adjustAmount">
          <InputNumber
            v-model="formData.amount"
            style="width: 100%; color: #fff; background: #1a1a1a"
            placeholder="请填写调整项金额"
          ></InputNumber>
        </FormItem>
         <FormItem label="生效时间（该时间作为对应结算周期的生效时间）" prop="chargeTime">
            <DatePicker
          placeholder="生效时间"
          type="date"
          v-model="formData.chargeTime"
          size="small"
          style="width: 100%"
        ></DatePicker>
        </FormItem>
        <FormItem label="调整说明">
          <Input
            v-model="formData.mark"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 5 }"
            placeholder="调整说明"
          />
        </FormItem>
      </Form>

      <div slot="footer">
        <Button type="text"  @click="modalAdd=false">取消</Button>
        <Button type="primary"  @click="handleSave">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  getbilloutline,
  getfeeitempage,
  getmspfeeitempage,
  batchaddorremovefeeitemlist,
  getadjustablefeetypelist,
  addorupdateadjustfeeitemtobill,
  getadjustfeeitempage,
  removeadjustfeeitemfrombill,
  updatebill,
  submitbill,
  getbillamountstructurewithtaxratio,
  setbilltaxratio
} from '@/api/statement/bill'
import { getPenaltyPage } from '@/api/statement/penalty'
import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule, ParseDate } from '@/utils/dateFormat'
import AddFeeitem from './AddFeeitem'
import AddFineItem from './AddFineItem'
import fileUpload from '@/components/common/FileUpload'
import { getMaintenanceAssetList } from '@/api/rim/asset'

export default {
  components: {
    AddFeeitem,
    AddFineItem,
    fileUpload
  },
  props: {},
  data () {
    return {
      // 附件
      modaluploadFile: false,
      fileTypes: ['img', 'jpg', 'png', 'doc', 'docx', 'pdf', 'xls', 'xlsx'],

      billDetail: {},
      feeitempage: [],
      feeitemType: 1,
      column: [],

      feeitemTotal: 0,
      feeitemColumn: [
        {
          title: '合同编号',
          key: 'contractCode'
        },
        {
          title: '客户',
          key: 'advertiserName'
        },
        {
          title: '品牌',
          key: 'brandName',
          render: (h, params) => {
            return h('div', [h('p', params.row.brandName || '-')])
          }
        },
        {
          title: '合同总额',
          key: 'contractAmount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.contractAmount)
              )
            ])
          }
        },

        {
          title: '执行周期',
          key: 'startDate',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                GetCurrentSchedule(params.row.startDate, params.row.endDate)
              )
            ])
          }
        },
        {
          title: '执行金额',
          key: 'periodExecutedAmount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.periodExecutedAmount)
              )
            ])
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              this.allowEdit
                ? h(
                  'a',
                  {
                    style: { marginRight: '5px', color: '#ef4f4f' },
                    on: {
                      click: () => {
                        this.removeItem(
                          1,
                          JSON.stringify([params.row.feeitemId])
                        )
                      }
                    }
                  },
                  '移除'
                )
                : null
            ])
          }
        }
      ],
      mspfeeitemColumn: [
        {
          title: '供应商',
          key: 'supplierCompanyName',
          render: (h, params) => {
            return h('div', [h('p', params.row.supplierCompanyName)])
          }
        },
        {
          title: '代理商',
          key: 'createCompanyName',
          render: (h, params) => {
            return h('div', [h('p', params.row.createCompanyName)])
          }
        },
        {
          title: '资源编号',
          key: 'resourceCode',
          render: (h, params) => {
            return h('div', [h('p', params.row.resourceCode)])
          }
        },
        {
          title: '资产',
          key: 'code',
          render: (h, params) => {
            return h('div', [h('p', params.row.assetName)])
          }
        },
        {
          title: '位置',
          key: 'position',
          render: (h, params) => {
            return h('div', [h('p', params.row.position)])
          }
        },
        {
          title: '资源类型',
          key: 'startDate',
          render: (h, params) => {
            return h('div', [h('p', params.row.resourceTypeName)])
          }
        },

        {
          title: '作业类型',
          key: 'taskTypeName',
          render: (h, params) => {
            return h('div', [h('p', params.row.taskTypeName)])
          }
        },
        {
          title: '客户',
          key: 'advertiserName'
        },
        {
          title: '品牌',
          key: 'brandName',
          render: (h, params) => {
            return h('div', [h('p', params.row.brandName || '-')])
          }
        },
        {
          title: '用途',
          key: 'adTypeName',
          render: (h, params) => {
            return h('div', [h('p', params.row.adTypeName || '-')])
          }
        },
        {
          title: '记账金额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        {
          title: '单价',
          key: 'unitPrice'
        },
        {
          title: '计费数量',
          key: 'quantity'
        },
        {
          title: '作业日期',
          key: 'taskRequireFinishTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.taskRequireFinishTime)])
          }
        },
        {
          title: '作业完成时间',
          key: 'taskFinishTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.taskFinishTime)])
          }
        },
        {
          title: '质检时间',
          key: 'qualityCheckTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.qualityCheckTime)])
          }
        },
        {
          title: '记账时间',
          key: 'confirmTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.confirmTime)])
          }
        },
        {
          title: '备注',
          key: 'mark',
          render: (h, params) => {
            return h('div', [h('p', params.row.mark || '-')])
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              this.allowEdit
                ? h(
                  'a',
                  {
                    style: { marginRight: '5px', color: '#ef4f4f' },
                    on: {
                      click: () => {
                        this.removeItem(
                          2,
                          JSON.stringify([params.row.feeitemId])
                        )
                      }
                    }
                  },
                  '移除'
                )
                : null
            ])
          }
        }
      ],
      feeitemQuery: {
        pageSize: 15,
        pageNumber: 1,
        feeitemType: null,
        keyword: ''
      },
      // 调整项
      modalAdd: false,
      adjustablefeetypeArray: [],
      formData: {
        billId: 0,
        feeType: null,
        amount: 0,
        mark: ''
      },
      formDataValidate: {
        feeType: [
          {
            required: true,
            type: 'number',
            message: ' ',
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            type: 'number',
            message: ' ',
            trigger: 'blur'
          }
        ]
      },

      adjustfeeitemTotal: 0,
      adjustfeeitemQuery: {
        pageSize: 15,
        pageNumber: 1
      },
      adjustfeeitems: [],
      adjustfeeitemColumn: [
        {
          title: '类别',
          key: 'feeTypeName'
        },
        {
          title: '备注',
          key: 'mark'
        },
        {
          title: '金额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        {
          title: '生效时间',
          key: 'chargeTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.chargeTime)])
          }
        },
        {
          title: '创建时间',
          key: 'createTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.createTime)])
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              this.allowEdit
                ? h(
                  'a',
                  {
                    style: { marginRight: '5px' },
                    on: {
                      click: () => {
                        this.formData.feeType = params.row.feeType
                        this.formData.amount = params.row.amount
                        this.formData.mark = params.row.mark
                        this.formData.feeitemId = params.row.feeitemId
                        this.modalAdd = true
                      }
                    }
                  },
                  '编辑'
                )
                : null,
              this.allowEdit
                ? h(
                  'a',
                  {
                    style: { marginRight: '5px', color: '#ef4f4f' },
                    on: {
                      click: () => {
                        const that = this
                        that.$Modal.confirm({
                          title: '确定移除？',
                          content:
                              '<p>确定要在当前结算单中移除该调整项？</p>',
                          loading: true,
                          onOk: () => {
                            const query = {
                              billId: that.billId,
                              feeitemId: params.row.feeitemId
                            }
                            removeadjustfeeitemfrombill(query)
                              .then((res) => {
                                if (res && !res.errcode) {
                                  that.$Notice.success({
                                    desc: '移除调整项成功！'
                                  })

                                  that.initBillDetail()
                                  that.initAdjustfeeitems()
                                  that.initbillamountstructurewithtaxratio()
                                  that.$Modal.remove()
                                }
                              })
                              .catch(() => {
                                that.$Modal.remove()
                              })
                          }
                        })
                      }
                    }
                  },
                  '移除'
                )
                : null
            ])
          }
        }
      ],
      // 罚款
      fineTotal: 0,
      fineQuery: {
        pageSize: 15,
        pageNumber: 1
      },
      fines: [],
      fineColumns: [
        { title: '罚款日期', align: 'center', key: 'penaltyDate' },
        {
          title: '罚款金额',
          align: 'center',
          key: 'amount',
          render: (h, params) => {
            // return toMoney(params.row.amount)
            return h('span', { class: 'money' }, toMoney(params.row.amount))
          }
        },
        { title: '罚款原因', align: 'center', key: 'content' },
        { title: '创建时间', align: 'center', key: 'createTime' },
        { title: '创建人员', align: 'center', key: 'createUserName' },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              this.allowEdit
                ? h(
                  'a',
                  {
                    style: { marginRight: '5px', color: '#ef4f4f' },
                    on: {
                      click: () => {
                        this.removeItem(3, JSON.stringify([params.row.id]))
                      }
                    }
                  },
                  '移除'
                )
                : null
            ])
          }
        }
      ],
      allowEdit: false, // 允许编辑
      allowConfirm: false, // 允许确认,

      assetList: [],

      summary: [],
      summaryColumns: [
        { title: '任务类型', align: 'center', key: 'feeTypeName' },
        {
          title: '不含税价',
          align: 'center',
          key: 'amountExcludeTax',
          render: (h, params) => {
            // return toMoney(params.row.amount)
            return h('span', toMoney(params.row.amountExcludeTax))
          }
        },
        {
          title: '税点',
          align: 'center',
          key: 'taxRatio',
          render: (h, params) => {
            return h('span', params.row.needTaxRatio ? (params.row.taxRatio * 100).toFixed(2) + '%' : '-')
          }
        },
        {
          title: '含税价',
          align: 'center',
          key: 'amount',
          render: (h, params) => {
            // return toMoney(params.row.amount)
            return h('span', toMoney(params.row.amount))
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              this.allowEdit && params.row.needTaxRatio
                ? h(
                  'a',
                  {
                    style: { marginRight: '5px' },
                    on: {
                      click: () => {
                        this.handleChangeTaxRate(params.row)
                        // this.removeItem(3, JSON.stringify([params.row.id]))
                      }
                    }
                  },
                  '调整税率'
                )
                : null
            ])
          }
        }
      ]
    }
  },
  created () {
    this.initAssetArray()
    this.feeitemQuery.billId = this.billId
    this.feeitemType = this.moduleType === 'transaction' ? 1 : 2
    this.initfeeitemColumn()
    this.initBillDetail()
    this.searchfeeitems()
    this.initAdjustfeeitems()
    this.Initadjustablefeetypelist()
    this.initBillFineArray()
    this.initbillamountstructurewithtaxratio()
  },
  mounted () {},
  computed: {
    billId () {
      return this.$store.state.reconciliationNew.billId
    },
    beginUpdate () {
      return this.$store.state.reconciliationNew.beginUpdate
    },
    publisherId () {
      return this.$store.state.reconciliationNew.publisherId
    },
    moduleType () {
      return this.$store.state.reconciliationNew
        ? this.$store.state.reconciliationNew.moduleType
        : 'transaction'
    }
  },
  methods: {
    // 计费项分页变更
    feeitemChangePage (currentPage) {
      this.feeitemQuery.pageNumber = currentPage
      this.initbillList()
    },
    // 计费项页大小变更
    handlePageSizeChanged (pagesize) {
      this.feeitemQuery.pageNumber = 1
      this.feeitemQuery.pageSize = pagesize
      this.initbillList()
    },

    // 调整项分页变更
    adjustfeeitemChangePage (currentPage) {
      this.adjustfeeitemQuery.pageNumber = currentPage
      this.initAdjustfeeitems()
    },
    // 调整项页大小变更
    handleadjustfeeitemPageSizeChanged (pagesize) {
      this.adjustfeeitemQuery.pageNumber = 1
      this.adjustfeeitemQuery.pageSize = pagesize
      this.initAdjustfeeitems()
    },

    // 罚款项分页变更
    fineChangePage (currentPage) {
      this.fineQuery.pageNumber = currentPage
      this.initBillFineArray()
    },
    // 罚款项页大小变更
    handlefinePageSizeChanged (pagesize) {
      this.fineQuery.pageNumber = 1
      this.fineQuery.pageSize = pagesize
      this.initBillFineArray()
    },

    initfeeitemColumn () {
      const that = this
      if (that.feeitemType === 1) {
        that.column = that.feeitemColumn
      } else {
        that.column = that.mspfeeitemColumn
      }
    },
    formatMoney (number) {
      return toMoney(number)
    },
    // 数据求汇总
    handleSummary ({ columns, data }) {
      const needSummarykey = ['amount', 'amountExcludeTax', 'contractAmount', 'periodExecutedAmount']
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          }
          return
        }
        if (needSummarykey.includes(key)) {
          const values = data.map((item) => Number(item[key]))
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[key] = {
              key,
              value: toMoney(v)
            }
          } else {
            sums[key] = {
              key,
              value: '-'
            }
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })

      return sums
    },
    // 加载结算单基本信息
    initBillDetail () {
      const that = this
      getbilloutline({ billId: that.billId }).then((res) => {
        that.billDetail = res
        that.allowEdit =
          that.billDetail.status === 0 &&
          ((that.moduleType === 'transaction' &&
            that.isAuth('transaction_createSettlement')) ||
            (that.moduleType === 'maintenance' &&
              that.isAuth('maintenance_createSettlement')))
        that.allowConfirm =
          that.billDetail.status === 0 &&
          ((that.moduleType === 'transaction' &&
            that.isAuth('transaction_confirmSettlement')) ||
            (that.moduleType === 'maintenance' &&
              that.isAuth('maintenance_confirmSettlement')))
      })
    },
    // 搜索计费项
    searchfeeitems () {
      this.feeitemQuery.pageNumber = 1
      this.feeitempage = []
      this.initbillList()
    },
    // 加载所有结算项(分页)
    initbillList () {
      const that = this
      that.feeitemQuery.feeitemType = that.feeitemType
      if (that.feeitemType === 1) {
        getfeeitempage(that.feeitemQuery).then((res) => {
          that.feeitempage = res.list
          that.feeitemTotal = res.totalRow
        })
      } else {
        getmspfeeitempage(that.feeitemQuery).then((res) => {
          that.feeitempage = res.list
          that.feeitemTotal = res.totalRow
        })
      }
    },

    addFeeitems () {
      this.$refs.addFeeitemModal.showModal(
        this.feeitemType,
        this.billDetail,
        // this.feeitemType === 1
        //   ? this.billDetail.agentCompanyId
        //   : this.billDetail.supplierCompanyId,
        this.billId
      )
    },
    removeItem (feeitemType, feeitemListJsonStr) {
      const that = this
      that.$Modal.confirm({
        title: '确定移除？',
        content: '<p>确定要在当前结算单中移除该计费项？</p>',
        loading: true,
        onOk: () => {
          const query = {
            billId: that.billId,
            feeitemType: feeitemType,
            feeitemListJsonStr: feeitemListJsonStr,
            action: -1
          }
          batchaddorremovefeeitemlist(query)
            .then((res) => {
              if (res && !res.errcode) {
                that.$Notice.success({ desc: '操作成功!' })
                switch (feeitemType) {
                  case 1:
                    that.searchfeeitems()
                    break
                  case 2:
                    that.searchfeeitems()
                    break
                  case 3:
                    that.initBillFineArray()
                    break
                  default:
                    break
                }
                that.initBillDetail()
                that.initbillamountstructurewithtaxratio()
                that.$Modal.remove()
              }
            })
            .catch(() => {
              that.$Modal.remove()
            })
        }
      })
    },

    // 获取调整项类型
    Initadjustablefeetypelist () {
      const that = this
      getadjustablefeetypelist({ billType: this.feeitemType }).then((res) => {
        that.adjustablefeetypeArray = res
      })
    },

    // 打开添加调整项的弹窗
    handleAdd () {
      this.formData.feeType = null
      this.formData.amount = null
      this.formData.mark = ''
      this.formData.feeitemId = null
      this.modalAdd = true
    },
    /**
     * 保存人工调整项
     */
    handleSave () {
      const that = this
      that.$refs.adjustment.validate((valid) => {
        if (valid) {
          that.formData.billId = that.billId
          if (that.formData.chargeTime) {
            that.formData.chargeTime = ParseDate(that.formData.chargeTime)
          }
          addorupdateadjustfeeitemtobill(that.formData).then((res) => {
            if (res && res.errcode === 0) {
              that.initBillDetail()
              that.initAdjustfeeitems()
              that.initbillamountstructurewithtaxratio()
              that.$Notice.success({ desc: '操作成功' })
            } else {
              that.$Notice.error({ title: '操作失败', desc: res.errmsg })
            }
            that.modalAdd = false
          })
        }
      })
    },
    // 加载调整项列表
    initAdjustfeeitems () {
      const that = this
      getadjustfeeitempage({
        billId: that.billId,
        pageSize: that.adjustfeeitemQuery.pageSize,
        pageNumber: that.adjustfeeitemQuery.pageNumber
      }).then((res) => {
        that.adjustfeeitems = res.list
        that.adjustfeeitemTotal = res.totalRow
      })
    },

    // 罚款项
    addFineItems () {
      this.$refs.addFineItemModal.showModal(
        this.feeitemType,
        this.feeitemType === 1
          ? this.billDetail.agentCompanyId
          : this.billDetail.supplierCompanyId,
        this.billId,
        this.publisherId
      )
    },
    // 加载结算单内所有罚款项
    initBillFineArray () {
      const that = this
      const query = {
        feeType: this.feeitemType === 1 ? 53 : 52,
        // agentCompanyId: that.billDetail.agentCompanyId,
        publisherId: that.publisherId,
        status: 5,
        billId: that.billId,
        pageSize: that.fineQuery.pageSize,
        pageNumber: that.fineQuery.pageNumber
      }
      if (that.feeitemType === 1) {
        query.agentCompanyId = that.billDetail.agentCompanyId
      } else if (that.feeitemType === 2) {
        query.supplierCompanyId = that.billDetail.supplierCompanyId
      }
      getPenaltyPage(query).then((res) => {
        that.fineTotal = res.totalRow
        that.fines = res.list
      })
    },
    // 加载结算单内价格汇总
    initbillamountstructurewithtaxratio () {
      const that = this
      const query = {
        billId: that.billId
      }
      getbillamountstructurewithtaxratio(query).then((res) => {
        that.summary = res
      })
    },

    // 结算单更改备注
    handleChangeCode () {
      let newMark = this.billDetail.mark
      this.$Modal.confirm({
        title: '更改结算单的备注',
        render: (h) => {
          return h('Input', {
            props: {
              value: newMark
            },
            on: {
              'on-change': (event) => {
                newMark = event.target.value
              }
            }
          })
        },
        onOk: () => {
          updatebill({ billId: this.billId, mark: newMark, name: this.billDetail.name, zones: this.billDetail.zones }).then((res) => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '更改结算单备注成功' })
              this.initBillDetail()
            }
          })
        }
      })
    },
    // 结算单更改名称
    handleChangeName () {
      let newName = this.billDetail.name
      this.$Modal.confirm({
        title: '更改结算单的名称',
        render: (h) => {
          return h('Input', {
            props: {
              value: newName
            },
            on: {
              'on-change': (event) => {
                newName = event.target.value
              }
            }
          })
        },
        onOk: () => {
          updatebill({ billId: this.billId, mark: this.billDetail.mark, name: newName, zones: this.billDetail.zones }).then((res) => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '更改结算单名称成功' })
              this.initBillDetail()
            }
          })
        }
      })
    },
    // 税率变更
    handleChangeTaxRate (taxInfo) {
      let taxValue = (taxInfo.taxRatio * 100).toFixed(2)
      this.$Modal.confirm({
        title: '更改' + taxInfo.feeTypeName + '税率',
        render: (h) => {
          return h('Input', {
            props: {
              type: 'number',
              min: 0,
              value: taxValue
            },
            on: {
              'on-change': (event) => {
                taxValue = event.target.value
              }
            }
          })
        },
        onOk: () => {
          setbilltaxratio({ billId: this.billId, feeItemType: taxInfo.feeItemType, feeType: taxInfo.feeType, taxRatio: (taxValue / 100) }).then((res) => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '更改' + taxInfo.feeTypeName + '税率成功！' })
              this.initbillamountstructurewithtaxratio()
            }
          })
        }
      })
    },
    // 加载所有可选线路
    initAssetArray () {
      getMaintenanceAssetList().then(res => {
        this.assetList = res
      })
    },
    // 结算单更改名称
    handleChangeAssets () {
      const that = this
      let assetNames = this.billDetail.zones.split(',')
      that.$Modal.confirm({
        title: '更改结算单的资产',
        render: (h) => {
          return h('Select', {
            props: {
              multiple: true,
              value: assetNames
            },
            on: {
              'on-change': (event) => {
                assetNames = event
              }
            }
          },
          that.assetList.map((item) => {
            return h('Option', {
              props: {
                value: item.name,
                label: item.name
              }
            })
          })
          )
        },
        onOk: () => {
          updatebill({ billId: this.billId, mark: this.billDetail.mark, name: this.billDetail.name, zones: assetNames.toString() }).then((res) => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '更改资产成功' })
              this.initBillDetail()
            }
          })
        }
      })
    },
    // 确认执行额
    confirmSettlement () {
      const that = this
      that.$Modal.confirm({
        title: '确认执行额',
        content:
          '<p>确认执行额以后所有计费项将不可再次编辑，此操作不可逆转，确认继续？</p>',
        onOk: () => {
          submitbill({ billId: that.billId }).then((res) => {
            if (res && !res.errcode) {
              that.$Notice.success({ desc: '确认执行额成功！' })
              that.initBillDetail()
            }

            that.$Modal.remove()
          })
        }
      })
    }
  },
  watch: {
    billId (val) {
      this.initBillDetail()
    },
    beginUpdate (val) {
      this.initBillDetail()
      this.searchfeeitems()
      this.initBillFineArray()
      this.initbillamountstructurewithtaxratio()
    }
  }
}
</script>
